import React, {  Component } from 'react';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import { mudarStream, mudarDevices, mudarCamModal } from '../actions/AppActions';
import { connect } from 'react-redux';

class ChangeCamModal extends Component {


   
    closeModal() {
        this.props.mudarCamModal(false);
    }

    change_device(event) {
        this.props.mudarDevices({ devices_camera: this.props.devices_camera, devices_mic: this.props.devices_mic, device_select: event.target.value })

        // console.log(event);
        localStorage.setItem("deviceId", event.target.value);

        navigator.mediaDevices.getUserMedia({ video: { deviceId: event.target.value,frameRate: 4,
            facingMode: "user",
            width: 320,
            height: 240 }, audio: true }).
            then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
    }
    handleSuccess(stream) {
        console.log('finalizado stream3')

        this.props.stream.oninactive = () => {}
        this.props.stream.getTracks().map((track)=>{
            track.stop();
        })
        // screenshotButton.disabled = false;
        // window.location="/processos_user"
        this.props.mudarStream(stream);
        this.props.changeStateParent({ left_movement:false,face_detected:false,right_movement:false,center_movement:false });
    }

    handleError(stream) {
        console.log(stream);
        let msg = '';
        if (stream.toString().includes('Permission denied')) {
            msg = 'Você bloqueou seu acesso a camera. Para liberá-lo vá nas configurações de seu navegador.';
        }
        else if (stream.toString().includes('Permission dismissed')) {
            msg = 'Aceita as permissões de acesso a câmera para poder avançar.';
        }
        else if (stream.toString().includes('Could not start video source')) {
            msg = 'Não foi possível acessar sua câmera. Conecte uma camera, feche todas suas outras aplicações e tente novamente.';
        }
        else if (stream.toString().includes('Could not start audio source')) {
            msg = 'Não foi possível acessar seu microfone. Conecte um microfnoe, feche todas suas outras aplicações e tente novamente.';
        }
        else if (stream.toString().includes('NotFoundError')) {
            msg = 'Não foram reconhecidos nenhuma câmera ou microfone conectados em seu computador. Conecte-os e tente novamente.';
        }
        else {
            msg = stream.toString();
        }

    }

    render() {

        return (
            <div>
                {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
                <Modal show={this.props.change_cam_modal} onHide={this.closeModal.bind(this)}  >
                    <Modal.Header closeButton={false}><Modal.Title>Escolher câmera</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <select value={this.props.device_select} className="form-control" id="cameras" onChange={this.change_device.bind(this)}>
                                {this.props.devices_camera.map((item, id) => (
                                    <option key={id} value={item.deviceId}>{item.label}</option>

                                ))}
                            </select>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button color="primary" onClick={this.change_device.bind(this)}>Alterar</Button>{' '} */}
                        <Button color="secondary" onClick={this.closeModal.bind(this)}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

const mapsStateToProps = (state) => (
    {
        change_cam_modal: state.AppReducer.change_cam_modal,
        devices_camera: state.AppReducer.devices_camera,
        devices_mic: state.AppReducer.devices_mic,
        device_select: state.AppReducer.device_select,
        stream: state.AppReducer.stream,
    }
);

export default connect(mapsStateToProps, { mudarCamModal, mudarDevices, mudarStream })(ChangeCamModal);