import React, { useEffect, useRef } from "react";
import ChatRoom from "./ChatRoom";
import VideoArea from "./VideoArea";
import UserInfoForm from "./UserInfoForm";
import RightContentWrapper from "./RightContentWrapper";
import { useSelector } from "react-redux";
import './style.css';
const MODE_STREAM = "stream";
const MODE_SHARE_SCREEN = "share_screen";

const configuration = {
  iceServers: [
    { urls: "stun:stun.1.google.com:19302" },
    {
      'urls': 'turn:turnserver.ontest.com.br:3478?transport=udp',
      'credential': 'Coconuts69341228#',
      'username': 'onteste_projetos'
    },
  ],
  sdpSemantics: 'unified-plan'
};
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}
let silence = () => {
  let ctx = new AudioContext(), oscillator = ctx.createOscillator();
  let dst = oscillator.connect(ctx.createMediaStreamDestination());
  oscillator.start();
  return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
}

let black = ({ width = 640, height = 480 } = {}) => {
  let canvas = Object.assign(document.createElement("canvas"), { width, height });
  canvas.getContext('2d').fillRect(0, 0, width, height);
  let stream = canvas.captureStream();
  return Object.assign(stream.getVideoTracks()[0], { enabled: false });
}

var startX, startY, startWidth, startHeight;
var positionDragResize='top';

function initDrag(e) {
  var elem = document.getElementById('meet_container');
  startX = e.clientX;
  startY = e.clientY;
  startWidth = parseInt(document.defaultView.getComputedStyle(elem).width, 10);
  startHeight = parseInt(document.defaultView.getComputedStyle(elem).height, 10);
  document.documentElement.addEventListener('mousemove', doDrag, false);
  document.documentElement.addEventListener('mouseup', stopDrag, false);
}

function doDrag(e) {
  document.body.style.userSelect='none';
  var elem = document.getElementById('meet_container');
  if(positionDragResize=='top'){
    // console.log(startWidth,e.clientX,startX)
    elem.style.width = (startWidth - e.clientX + startX) + 'px';
    elem.style.height = (startHeight - e.clientY + startY) + 'px';
  }
  else{
    if(e.clientX-parseInt(document.defaultView.getComputedStyle(elem).width)<0){
      return;
    }
    else if(e.clientX>window.innerWidth-10){
      return;
    }
    else{
      elem.style.left = e.clientX-parseInt(document.defaultView.getComputedStyle(elem).width)+'px';

    }
    if(e.clientY<15){
      return;
    }
    else if(e.clientY+parseInt(document.defaultView.getComputedStyle(elem).height)>window.innerHeight){
      return;
    }
    else{
      elem.style.top =e.clientY  + 'px';
    }
  }
  // else{
  //   elem.style.width = (startWidth + e.clientX - startX) + 'px';
  //   elem.style.height = (startHeight + e.clientY - startY) + 'px';
  // }
}

function stopDrag(e) {
  document.body.style.userSelect='text';
   document.documentElement.removeEventListener('mousemove', doDrag, false);    
   document.documentElement.removeEventListener('mouseup', stopDrag, false);
}

export default function MeetingRoom(props) {
  console.log(props)
  const [focused_participant, setFocusedParticipant] = React.useState(null);
  let userSocket = null;

  const localScreen = React.useRef();
  const localStreamScreen = React.useRef();

  const participants = React.useRef();

  const isConnectedRef = React.useRef();
  const isConnectedSocketRef = React.useRef();

  const [show_chat, setShowChat] = React.useState(false);
  const [show_info, setShowInfo] = React.useState(false);
  const user = useSelector(store => store.AppReducer.user);


  const [show_participants, setShowParticipants] = React.useState(false);


  const localVideo = React.useRef();
  const localStream = React.useRef();
  const clientId = React.useRef();
  const device = React.useRef();
  const producerTransport = React.useRef();
  const videoProducer = React.useRef({});
  const audioProducer = React.useRef({});
  const consumerTransport = React.useRef();
  const videoConsumers = React.useRef({});
  const audioConsumers = React.useRef({});
  const consumersStream = React.useRef({});
  const list_peers = React.useRef();

  const isShareScreenRef = React.useRef();

  const [useVideo, setUseVideo] = React.useState(true);
  const [payAttention, setPayAttention] = React.useState(false);

  const [useAudio, setUseAudio] = React.useState(true);
  const [isStartMedia, setIsStartMedia] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(false);
  const [remoteVideos, setRemoteVideos] = React.useState({});
  const [isShareScreen, setIsShareScreen] = React.useState(false);
  const [roomName, setRoomName] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [messageWarning, setMessageWarning] = React.useState("");
  const timeout_join = useRef();

  // ============ STREAMING CONTROL START ==========
  const [enableVideoStream, setEnableVideoStream] = React.useState(useVideo);
  const [enableAudioStream, setEnableAudioStream] = React.useState(useAudio);

  const [enableVideoShare, setEnableVideoShare] = React.useState(useVideo);
  const [enableAudioShare, setEnableAudioShare] = React.useState(useAudio);

  const socket = useSelector(store => store.AppReducer.socket);
  const connect = useSelector(store => store.AppReducer.connect);


  const stream = useSelector(store => store.AppReducer.stream);
  const stream_screen = useSelector(store => store.AppReducer.stream_screen);

  const focused_participant_ref = useRef();

  useEffect(()=>{
    focused_participant_ref.current=focused_participant;
  },[focused_participant])

  const replaceVideoTracks = (type, id_track, new_track) => {
    Object.keys(list_peers.current).map((key) => {
      if (list_peers.current[key] != undefined) {
        list_peers.current[key].getSenders().map(async (sender) => {
          if (sender.track.kind == type && id_track == sender.track.id) {
            await sender.replaceTrack(new_track)
          }
          return sender;
        })
      }
    })
  }

  useEffect(()=>{
    isConnectedSocketRef.current=connect;
    if(connect==true && timeout_join.current==null && user?.accessibility==true && user?.type_supervisor!='none' && props.processo?.is_interview==false){
      console.log('SCREEEN sharing',isShareScreenRef.current)
      handleConnect();
    }
  },[connect])



  const handleEnableVideoStream = async (stream, cond) => {
    // stream.getVideoTracks()[0].enabled = cond;
  };
  const handleEnableAudioStream = (stream, cond) => {
    
  };
  const handleEnableVideoShare = (stream, cond) => {
    if (!stream) {
      return false;
    }
    stream.getVideoTracks()[0].enabled = cond;
    setEnableVideoShare(cond);
  };
  const handleEnableAudioShare = (stream, cond) => {
    if (!stream) {
      return false;
    }
    stream.getAudioTracks()[0].enabled = cond;
    setEnableAudioShare(cond);
  };

  // ============STREAMING CONTROL END ==========
  // ============ ROOM START ==========

  const handleRoomName = (e) => {
    setRoomName(e.target.value);
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };
  // ============ ROOM END ==========

  // ============ SHARE SCREEN START ==========
  const handleStartScreenShare = () => {
    if (localStreamScreen.current) {
      console.warn("WARN: local media ALREADY started");
      return;
    }

    const mediaDevices = navigator.mediaDevices;
    mediaDevices
      .getDisplayMedia({  video: useVideo })
      .then((stream) => {
        localStreamScreen.current = stream;

        // playVideo(localScreen.current, localStreamScreen.current, 0);
        handleConnectScreenShare();
        const screenTrack = stream.getVideoTracks()[0];
        screenTrack.onended = function () {
          handleDisconnectScreenShare();
        };


          participants.current[props.getAdminId()].video_show='screen';
          participants.current[props.getAdminId()].screen = true;
          participants.current[props.getAdminId()].video_track_screen = screenTrack;
          let stream_new=new MediaStream();
          let track_old = participants.current[props.getAdminId()].stream.getVideoTracks()[1];

          stream_new.addTrack(participants.current[props.getAdminId()].stream.getAudioTracks()[0]);
          stream_new.addTrack(participants.current[props.getAdminId()].stream.getVideoTracks()[0]);
          stream_new.addTrack(screenTrack);

  
          participants.current[props.getAdminId()].stream = stream_new;
          localStream.current = stream_new;
          consumersStream.current = stream_new;
          
          let remotes = { ...participants.current }
            replaceVideoTracks('video',track_old.id,screenTrack);
            track_old.stop();
          // else{
          // localVideo.current.srcObject=stream_new;
          // }
          setRemoteVideos(remotes);
          isShareScreenRef.current=true;
        setIsShareScreen(true);
      socket.emit('update_config_call',{...participants.current[props.getAdminId()],aluno_id:props.student.id,processo_id:props.processo.id});


      })
      .catch((err) => {
        console.error("media ERROR:", err);
      });
  };

  async function handleConnectScreenShare() {
    if (!localStreamScreen.current) {
      console.warn("WARN: local media NOT READY");
      return;
    }

    // // --- connect socket.io ---
    // await connectSocket().catch((err: any) => {
    //     console.error(err);
    //     return;
    // });

    // console.log('connected');

    // --- get capabilities --

  }

  useEffect(()=>{
    isConnectedRef.current=isConnected;
  },[isConnected])

  function handleStopScreenShare() {
    if (localStreamScreen.current) {
      stopLocalStream(localStreamScreen.current);
      localStreamScreen.current = null;
      isShareScreenRef.current=false;
      setIsShareScreen(false);
    }
  }
  async function handleDisconnectScreenShare() {
    if(participants.current[props.getAdminId()]!=undefined){

    participants.current[props.getAdminId()].screen = false;
    if(participants.current[props.getAdminId()].video_track_screen!=null){
      participants.current[props.getAdminId()].video_track_screen.stop();
    }
    participants.current[props.getAdminId()].video_track_screen = null;
    participants.current[props.getAdminId()].video_show='camera';

    localStreamScreen.current=null;
    let remotes = { ...participants.current }
    // else{
    // localVideo.current.srcObject=stream_new;
    // }
    console.log('disconnect screen sharing')
    setRemoteVideos(remotes);
    isShareScreenRef.current=false;
  setIsShareScreen(false);
  socket.emit('update_config_call',{...participants.current[props.getAdminId()],aluno_id:props.student.id,processo_id:props.processo.id});
}

  }

  useEffect(()=>{
    if(stream!=null && participants.current[props.getAdminId()]!=undefined){
      participants.current[props.getAdminId()].video_cam = true;
      let track_old=participants.current[props.getAdminId()].video_track_cam;
        participants.current[props.getAdminId()].video_track_cam = stream.getVideoTracks()[0];
        let stream_new = new MediaStream();
        stream_new.addTrack(stream.getAudioTracks()[0]);

        if(isConnected){
        }
        stream_new.addTrack(stream.getVideoTracks()[0]);
        stream_new.addTrack(participants.current[props.getAdminId()].stream.getVideoTracks()[1]);
        stream_new.addTrack(stream.getAudioTracks()[0]);
        participants.current[props.getAdminId()].stream = stream_new;
        localStream.current = stream_new;
        consumersStream.current = stream_new;
        
        let remotes = { ...participants.current }
        if(isConnected==true){
          replaceVideoTracks('video',track_old.id,stream_new.getVideoTracks()[0]);
        }
        else{
        localVideo.current.srcObject=stream_new;
        }
        // stream.getVideoTracks()[0].stop();

       
        setRemoteVideos(remotes);
    }
  },[stream])

  useEffect(()=>{
    if(user?.accessibility==true && user?.type_supervisor!='none' && props.processo?.is_interview==false){
      if(focused_participant==null ||
        focused_participant[user.id]!=undefined
      ){
        let focused_participant_new=null;
        for(let i=0;i<Object.values(remoteVideos).length;i++){
          if(Object.values(remoteVideos)[i].id!=user.id){
            focused_participant_new={}
            focused_participant_new[Object.values(remoteVideos)[i].id] = Object.values(remoteVideos)[i];
          }
        }
        setFocusedParticipant(focused_participant_new);
      }
    }

  },[remoteVideos])

  // ============ SHARE SCREEN END ==========

  // ============ MEDIA START ==========
  const handleStartMedia = () => {
    if (localStream.current) {
      console.warn("WARN: local media ALREADY started");
      return;
    }
    list_peers.current = {}
    participants.current = {}
    consumersStream.current = {}
    let stream_new = new MediaStream();


    stream.getVideoTracks()[0].applyConstraints({
                                height: 800,
                                frameRate: 24
                            })

    stream.getTracks().map((track)=>{
      stream_new.addTrack(track);
    })
    console.log('stream screen',stream_screen)
    if(stream_screen!=null){
    console.log('stream screen12',stream_screen)
    stream_screen.getVideoTracks()[0].applyConstraints({
      height: 1200,
      frameRate: 24
  })
      stream_new.addTrack(stream_screen.getVideoTracks()[0]);
      isShareScreenRef.current=true;
      setIsShareScreen(true);

    }
    localStream.current = stream_new;
    console.log('MEDIA STARTED',user)
    if(user.accessibility==true && user.type_supervisor!='none' && props.processo?.is_interview==false){
      setIsStartMedia(true);
      handleConnect();
    }
    else{
      playVideo(localVideo.current, localStream.current, 0);
      setIsStartMedia(true);
    }
   
    
  };

  function handleStopMedia() {
    if (localStream.current) {
      console.log('stop media')
      stopLocalStream(localStream.current);
      localStream.current = null;
      setIsStartMedia(false);
    }
  }

  function handleDisconnect() {
    if(isConnected){
      console.log('exiting call')
      socket.emit('exit_call', { aluno_id: props.student.id, processo_id: props.processo.id, id: props.getAdminId() })
    }

    localStream.current = null;
    
    disconnectSocket();
    setIsConnected(false);
    setFocusedParticipant(null);
    if(socket!=null){

    socket.off('connect', connect_event)

    socket.off('disconnect', disconnect_event)
    socket.removeAllListeners('join_call');
      socket.removeAllListeners('new_participant');
      socket.removeAllListeners('remove_participant');
      socket.removeAllListeners('candidate_call');
      socket.removeAllListeners('sdp_call');
      socket.removeAllListeners('desc_call');
      socket.removeAllListeners('answer_call');
      socket.removeAllListeners('update_config_call');
    }
    props.closeCall();

  }

  function playVideo(element, stream, volume = 0) {
    // if (element.srcObject) {
    //   console.warn("element ALREADY playing, so ignore");
    //   return;
    // }
    element.srcObject = stream;
    console.log(element);
    console.log(stream);

    element.volume = volume;
    return element.play();
  }

  function pauseVideo(element) {
    try {
      element.pause();
      element.srcObject = null;
    } catch (err) {
      console.error("pauseVideo ERROR:", err);
    }
  }

  function stopLocalStream(stream) {
    let tracks = stream.getTracks();
    if (!tracks) {
      console.warn("NO tracks");
      return;
    }

    tracks.forEach((track) => track.stop());
  }

  function addRemoteTrack(id, track, mode) {
  }

  // function addRemoteVideo(id) {
  //   let existElement = findRemoteVideo(id);
  //   if (existElement) {
  //     console.warn("remoteVideo element ALREADY exist for id=" + id);
  //     return existElement;
  //   }

  //   let element = document.createElement("video");
  //   return element;
  // }

  // function findRemoteVideo(id) {
  //   let element = remoteVideos.current[id];
  //   return element;
  // }

  // function removeRemoteVideoByMode(id: any, mode: string) {
  //     console.log(' ---- removeRemoteVideo() id=' + id);
  //     delete consumersStream.current[id][mode];
  //     setRemoteVideos((peers: any) => {
  //         const newPeers: any = peers;
  //         delete newPeers[id][mode];

  //         return { ...peers, ...newPeers };
  //     });
  // }

  function removeRemoteVideo(id, mode) {
    console.log(" ---- removeRemoteVideo() id=" + id);
    if (mode == MODE_STREAM) {
      delete consumersStream.current[id];
    } else {
      delete consumersStream.current[id][mode];
    }

    setRemoteVideos((peers) => {
      const newPeers = peers;
      delete newPeers[id];

      return { ...consumersStream.current };
    });
    // if (element) {
    //     element.pause();
    //     element.srcObject = null;
    //     remoteContainer.removeChild(element);
    // } else {
    //     console.log('child element NOT FOUND');
    // }
  }

  function removeAllRemoteVideo() {
    console.log(" ---- removeAllRemoteVideo() id");
    consumersStream.current = {};
    setRemoteVideos((peers) => {
      const newPeers = {};

      return { ...newPeers };
    });
    // while (remoteContainer.firstChild) {
    //     remoteContainer.firstChild.pause();
    //     remoteContainer.firstChild.srcObject = null;
    //     remoteContainer.removeChild(remoteContainer.firstChild);
    // }
  }

  async function consumeAdd(
    transport,
    remoteSocketId,
    prdId,
    trackKind,
    mode = MODE_STREAM
  ) {

  }

  const onIceCandidate = function (peer, evt, admin_id, receive_id) {
    socket.emit("candidate_call", JSON.stringify({ "candidate": evt.candidate, sender_id: admin_id, receive_id: receive_id, processo_id: props.processo.id, aluno_id: props.student.id }));
  }


  const create_peer = (id) => {
    try{
    // let stream = this.choose_stream(id);

    // if(this.list_peers.current[id]!=undefined && this.list_peers.current[id].connectionState=='connected'){
    //     return;
    // }
    if (list_peers.current[id] != undefined) {
      list_peers.current[id].close();
    }
    list_peers.current[id] = new RTCPeerConnection(configuration);

    list_peers.current[id].onicecandidate = (evt) => {
      if (!evt.candidate) return;
      // console.log("onicecandidate called");
      onIceCandidate(list_peers.current[id], evt, props.getAdminId(), id);
    };
    list_peers.current[id].onaddstream = (evt) => {
      console.log("onaddstream called");
      consumersStream.current[id] = evt.stream;
      console.log('remoteVideos2', remoteVideos, id)
      console.log(participants.current[id])
      participants.current[id].stream = evt.stream;
      participants.current[id].video_track_cam = evt.stream.getVideoTracks()[0];
      participants.current[id].video_track_screen = evt.stream.getVideoTracks()[1];
      participants.current[id].audio_track = evt.stream.getAudioTracks()[0];
      
      console.log('participant1', participants.current[id])

      let remotes = { ...participants.current };
      setRemoteVideos(remotes)
      // document.getElementById("remoteview").srcObject = evt.stream;
    };
    // list_peers.current[id].oniceconnectionstatechange = function() {
    //     console.log(list_peers.current[id].iceConnectionState);
    // }
    list_peers.current[id].onconnectionstatechange = (e) => {
      console.log(e);
      console.log(list_peers.current[id].connectionState);
    }
    // let stream = new MediaStream();
    // this.props.stream.getTracks().forEach(track => stream.addTrack(track));
    // if(this.props.stream_screen!=null) this.props.stream_screen.getTracks().forEach(track => stream.addTrack(track));
    // this.list_peers.current[id].addStream(localStream.current);
    list_peers.current[id].addStream(localStream.current);


    // for (const track of this.props.stream.getTracks()) {
    //     list_peers.current[id].addTrack(track, this.props.stream);
    // }

    list_peers.current[id].createOffer().then(function (desc) {
      try {
        // console.log('sdp coonect');
        // if (good == false) {
        //     desc.sdp = this.handle_offer_sdp(desc)
        // }
        console.log('peer');

        list_peers.current[id].setLocalDescription(new RTCSessionDescription(desc)).then((s) => {
          // console.log(s);
        }).catch(function (e) {
          console.log('Erro webrtc');
          console.log(e);
          list_peers.current[id].close();
          create_peer(id);
        });

        socket.emit("sdp_call", JSON.stringify({ "sdp": desc, sender_id: props.getAdminId(), receive_id: id, aluno_id: props.student.id, processo: props.processo.id }));
      }
      catch (err) {
        // this.create_peer(id);
        console.log(err);
      }

    }).catch(function (e) {
      // console.log(e);
    });
  } catch(e){
    console.log(e);
  }
  }

  function connect_event(data) {
      console.log('connect_event','isConnected',isConnectedRef.current)
      setMessageWarning('');
      if(isConnectedRef.current){
        socket.emit('join_call', { video_cam: participants.current[props.getAdminId()].video_cam, audio: participants.current[props.getAdminId()].audio, screen: participants.current[props.getAdminId()].screen,type: 'Candidato', aluno_id: props.student.id, processo_id: props.processo.id, id: props.getAdminId(), name: user.name, id_adm: user.id });
      }
  }

  function disconnect_event(data) {
    setMessageWarning('Sua conexão está instável');
  }
  console.log('participants actual',participants.current)

  async function handleConnect() {

    console.log('connect',isConnectedSocketRef.current)
    console.log('screen sharing',isShareScreenRef.current)

    if (socket != null && isConnectedSocketRef.current == true) {
      if(user.accessibility==false || user.type_supervisor=='none' || props.processo?.is_interview==true){
        setShowParticipants(true);
      }

    setIsStartMedia(false);
    let stream = localStream.current;
    if (!localStream.current) {
      stream = new MediaStream();
      // console.warn("WARN: local media NOT READY");
      // return;
    }
    if (stream.getAudioTracks().length == 0) {
      let track = silence();
      track.stop();
      stream.addTrack(track)
    }
    for (let i = stream.getVideoTracks().length; i < 2; i++) {
      let track = black();
      track.stop();
      stream.addTrack(track)
    }
    localStream.current = stream;
    consumersStream.current[props.getAdminId()] = localStream.current;
    console.log(socket)
    console.log(localStream.current)

    console.log('connect')
      let participants_ = { ...participants.current };
      participants_[props.getAdminId()] = {
        ...user, id: props.getAdminId(), type: 'Candidato',
        stream: localStream.current,
        video_track_cam: localStream.current.getVideoTracks()[0],
        video_track_screen: localStream.current.getVideoTracks()[1],
        audio_track: localStream.current.getAudioTracks()[0],
        video_cam: enableVideoStream, audio: enableAudioStream, screen: isShareScreenRef.current,
        video_show:'camera'
      }
      console.log('set remove video4', participants_)
      participants.current = participants_;
      setRemoteVideos(participants_);

      socket.removeAllListeners('join_call');
      socket.removeAllListeners('new_participant');
      socket.removeAllListeners('remove_participant');
      socket.removeAllListeners('candidate_call');
      socket.removeAllListeners('sdp_call');
      socket.removeAllListeners('desc_call');
      socket.removeAllListeners('answer_call');
      socket.removeAllListeners('update_config_call');
      socket.off('connect', connect_event)

      socket.off('disconnect', disconnect_event)

      socket.on('connect', connect_event)

      socket.on('disconnect', disconnect_event)



      socket.on('new_participant', (data) => {
        if (data.id != props.getAdminId()) {

          let participant = { video_show:data.screen==true?'screen':'camera',type:data.type, id: data.id, name: data.name, stream: null, audio_track: null, video_track_cam: null, video_track_screen: null, video_cam: data.video_cam, audio: data.audio, screen: data.screen };
          let participantes = { ...participants.current };
          participantes[data.id] = participant;
          participants.current = participantes;
          console.log('set remove video1', participantes)
          setRemoteVideos(participantes);
        }


      })

      socket.on('remove_participant', (data) => {
        console.log('removed_participant',data)
        if (data.id != props.getAdminId()) {
          delete consumersStream.current[data.id];
          delete participants.current[data.id];
          let participantes = { ...participants.current };

          console.log('set remove video2', participantes)
          console.log('focused_participant_ref', focused_participant_ref)


          setRemoteVideos(participantes);
          setFocusedParticipant(null);
          if(focused_participant_ref.current!=null && focused_participant_ref.current[data.id]!=undefined){
            setFocusedParticipant(null);

          }
        }
      })

      socket.on('update_config_call', (data) => {
        console.log('update_config_call',data)
        data.number.map((item) => {
          let id = item.identifier;
          if(id==props.getAdminId()){
            return;
          }
          if(participants.current[id].screen==false && item.screen==true){
            participants.current[id] = {...participants.current[id],...item,video_show:'screen'};
          }
          else{
            participants.current[id] = {...participants.current[id],...item};
          }
          let participantes = { ...participants.current };
          setRemoteVideos(participantes);
          if(focused_participant_ref.current!=null && focused_participant_ref.current[id]!=undefined){
            setFocusedParticipant({
              [participants.current[id].id]: participants.current[id],
            });
          }
        })

      })

      socket.on('join_call', (data) => {
        clearTimeout(timeout_join.current);

        console.log('join_call', data);
        setIsStartMedia(true);

        setIsConnected(true)
        data.number.map((item) => {
          let id = item.identifier;
          let name = item.name;
          if (id != props.getAdminId()) {
            console.log('criando peer', id, props.getAdminId())
            let participant = { video_show:item.screen==true?'screen':'camera', id, name,type:item.type, stream: null, video_cam: item.video_cam, audio: item.audio, screen: item.screen, audio_track: null, video_track_cam: null, video_track_screen: null };
            participants.current[id] = participant;
            let participantes = { ...participants.current };
            setRemoteVideos(participantes);
            create_peer(id);
          }

        })

      })
      socket.on("candidate_call", function (msg) {
        // console.log("candidate received");
        if (list_peers.current[JSON.parse(msg).sender_id] != undefined && list_peers.current[JSON.parse(msg).sender_id].connectionState != 'closed') {
          list_peers.current[JSON.parse(msg).sender_id].addIceCandidate(new RTCIceCandidate(JSON.parse(msg).candidate));
        }

      });

      socket.on("answer_call", function (answer) {
        // console.log("answer received");
        // console.log(answer);

        list_peers.current[JSON.parse(answer).sender_id].setRemoteDescription(new RTCSessionDescription(JSON.parse(answer).sdp));
      });

      socket.on("sdp_call", function (msg) {
        console.log("sdp received");
        let id = JSON.parse(msg).sender_id;
        if (list_peers.current[id] != undefined) {
          list_peers.current[id].close();
        }
        list_peers.current[id] = new RTCPeerConnection(configuration);

        list_peers.current[id].onicecandidate = function (evt) {
          if (!evt.candidate) return;
          // console.log("onicecandidate called");
          onIceCandidate(list_peers.current[id], evt, props.getAdminId(), id);
        };

        list_peers.current[id].onaddstream = function (evt) {
          console.log("onaddstream called");
          // if (this.state.sala_select.alunos[id] != undefined) {

          //     this.state.sala_select.alunos[id].videoRef.current.srcObject = evt.stream;
          //     this.state.sala_select.alunos[id].stream = evt.stream;
          //     let user_id = id;
          //     this.audio_context_peers[user_id] = {}
          //     this.audio_context_peers[user_id]['context'] = new AudioContext();
          //     this.audio_context_peers[user_id]['analyser'] = this.audio_context_peers[user_id]['context'].createAnalyser();
          //     this.audio_context_peers[user_id]['frequencyData'] = null;
          //     this.audio_context_peers[user_id]['source'] = this.audio_context_peers[user_id]['context'].createMediaStreamSource(this.state.sala_select.alunos[id].stream);
          //     this.audio_context_peers[user_id]['source'].connect(this.audio_context_peers[user_id]['analyser']);
          //     this.audio_context_peers[user_id]['gainNode'] = this.audio_context_peers[user_id]['context'].createGain();
          //     this.audio_context_peers[user_id]['gainNode'].gain.value = 0.1; // setting it to 10%
          //     this.audio_context_peers[user_id]['gainNode'].connect(this.audio_context_peers[user_id]['context'].destination);
          //     // analyser.connect(context.destination);

          //     this.audio_context_peers[user_id]['context'].resume()
          //     this.audio_context_peers[user_id]['analyser'].fftSize = 32;
          //     this.audio_context_peers[user_id]['bufferLength'] = this.audio_context_peers[user_id]['analyser'].frequencyBinCount;
          //     this.audio_context_peers[user_id]['dataArray'] = new Uint8Array(this.audio_context_peers[user_id]['bufferLength']);
          //     this.audio_context_peers[user_id]['analyser'].getByteTimeDomainData(this.audio_context_peers[user_id]['dataArray']);
          //     this.audio_context_peers[user_id].id=this.uuidv4()
          //     // this.audio.srcObject = evt.stream
          //     this.draw_peer(user_id,this.audio_context_peers[user_id].id);

          //     console.log('full_screen_added1')
          //     // console.log(this.state.aluno_fullscreen,Object.values(this.state.sala_select.alunos)[this.state.aluno_fullscreen]?.id,id)
          //     if (this.state.aluno_fullscreen != -1 && this.state.aluno_fullscreen == id) {
          //         console.log('full_screen_added2')
          //         console.log(evt.stream)
          //         this.fullscreen_ref.current.srcObject = evt.stream;
          //     }
          // }
          consumersStream.current[id] = evt.stream;
          console.log('remoteVideos1', remoteVideos, id)
          participants.current[id].stream = evt.stream;
          participants.current[id].video_track_cam = evt.stream.getVideoTracks()[0];

          participants.current[id].video_track_screen = evt.stream.getVideoTracks()[1];
          participants.current[id].audio_track = evt.stream.getAudioTracks()[0];
          
          let remotes = { ...participants.current };
          setRemoteVideos(remotes)
          console.log('participant2', participants.current[id])

          // document.getElementById("video_tag" + JSON.parse(msg).aluno_id).srcObject = evt.stream;
        };
        list_peers.current[id].addStream(localStream.current);

        // list_peers.current[id].oniceconnectionstatechange = function() {
        //     console.log(list_peers.current[id].iceConnectionState);
        // }
        // list_peers.current[id].onconnectionstatechange = function() {
        //     console.log(list_peers.current[id].connectionState);
        // }   
        var sessionDesc = new RTCSessionDescription(JSON.parse(msg).sdp);
        list_peers.current[id].setRemoteDescription(sessionDesc);
        list_peers.current[id].createAnswer().then(function (sdp) {
          // if(this.good_quality_student[id]!=true){
          //     sdp.sdp = this.handle_offer_sdp(sdp)
          // }
          // else{
          //     console.log('NAO ENTROU SDP')
          // }
          console.log('anwser sdp');
          list_peers.current[id].setLocalDescription(new RTCSessionDescription(sdp));
          socket.emit("answer_call", JSON.stringify({ "sdp": sdp, sender_id: props.getAdminId(), receive_id: id, aluno_id: props.student.id, processo_id: props.processo.id }));
        }).catch(function (e) {
          console.log(e);
        });
      });
      console.log('admin_id1', props.getAdminId())
      console.log('admin_id2', props.getAdminId())
      timeout_join.current=setTimeout(()=>{
        setIsStartMedia(true)
      },10000);
      socket.emit('join_call', { video_cam: enableVideoStream, audio: enableAudioStream, screen: isShareScreenRef.current,type: 'Candidato', aluno_id: props.student.id, processo_id: props.processo.id, id: props.getAdminId(), name: user.name, id_adm: user.id });
    }
    else if(user.accessibility==true && user.type_supervisor!='none' && props.processo?.is_interview==false){
      console.log('fail connect')
      
    }





  }
  

  async function subscribe() {
    // console.log(socketRef.current);


    // --- get capabilities --

  }

  async function loadDevice(routerRtpCapabilities) {
    try {
      // device.current = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        console.error("browser not supported");
      }
    }
    await device.current.load({ routerRtpCapabilities });
  }

  function sendRequest(type, data) {

  }

  async function consumeCurrentProducers(clientId) {
    console.log("-- try consuleAll() --");
    const remoteInfo = await sendRequest("getCurrentProducers", {
      localId: clientId,
    }).catch(function (err) {
      console.error("getCurrentProducers ERROR:", err);
      return;
    });
    //console.log('remoteInfo.producerIds:', remoteInfo.producerIds);
    console.log("remoteInfo.remoteVideoIds:", remoteInfo.remoteVideoIds);
    console.log("remoteInfo.remoteAudioIds:", remoteInfo.remoteAudioIds);
    consumeAll(
      consumerTransport.current,
      remoteInfo.remoteVideoIds,
      remoteInfo.remoteAudioIds
    );
  }

  function consumeAll(transport, remoteVideoIds, remotAudioIds) {
    console.log("----- consumeAll() -----");

    remoteVideoIds.forEach((rId) => {
      consumeAdd(transport, rId, null, "video", MODE_STREAM).then((resp) => {
        consumeAdd(transport, rId, null, "video", MODE_SHARE_SCREEN);
      });
    });
    let audioIdsCount = 0;
    remotAudioIds.forEach((rId) => {
      consumeAdd(transport, rId, null, "audio", MODE_STREAM).then((resp) => {
        consumeAdd(transport, rId, null, "audio", MODE_SHARE_SCREEN);
      });
    });
  }

  function disconnectSocket() {
    Object.keys(list_peers.current).map((key) => {
      if (list_peers.current[key] != undefined) {
        list_peers.current[key].close();
      }
    })
    list_peers.current = {}
    participants.current = {}
    console.log('zerou 2')

    consumersStream.current = {}
    setRemoteVideos({});
  }

  function removeConsumer(id, kind, mode) {
    if (mode == undefined) {
      return false;
    }
    if (kind === "video") {
      if (mode == MODE_STREAM) {
        delete videoConsumers.current[id];
      } else {
        delete videoConsumers.current[id][mode];
      }

      console.log(
        "videoConsumers count=" + Object.keys(videoConsumers.current).length
      );
    } else if (kind === "audio") {
      if (mode == MODE_STREAM) {
        delete audioConsumers.current[id];
      } else {
        delete audioConsumers.current[id][mode];
      }

      console.log(
        "audioConsumers count=" + Object.keys(audioConsumers.current).length
      );
    } else {
      console.warn("UNKNOWN consumer kind=" + kind);
    }
  }

  // function getConsumer(id: any, kind: any) {
  //     if (kind === 'video') {
  //         return videoConsumers.current[id];
  //     } else if (kind === 'audio') {
  //         return audioConsumers.current[id];
  //     } else {
  //         console.warn('UNKNOWN consumer kind=' + kind);
  //     }
  // }

  function addConsumer(id, consumer, kind, mode) {
    if (id === clientId.current) {
      return false;
    }
    if (kind === "video") {
      if (videoConsumers.current[id] == undefined) {
        videoConsumers.current[id] = {};
      }
      videoConsumers.current[id][mode] = consumer;
      console.log(
        "videoConsumers count=" + Object.keys(videoConsumers.current).length
      );
    } else if (kind === "audio") {
      if (audioConsumers.current[id] == undefined) {
        audioConsumers.current[id] = {};
      }
      audioConsumers.current[id][mode] = consumer;

      console.log(
        "audioConsumers count=" + Object.keys(audioConsumers.current).length
      );
    } else {
      console.warn("UNKNOWN consumer kind=" + kind);
    }
  }

  const changeVideo = (type,id) => {
    participants.current[id].video_show=type;
    let remotes = {...participants.current};
    setRemoteVideos(remotes);
  };
  

  useEffect(() => {
   
    return () => {
      clearTimeout(timeout_join.current);
      handleDisconnect();
    }
  }, []);

  useEffect(()=>{
    console.log('update screen sharing use effect',isShareScreen)
  },[isShareScreen])

  

  // call handleDisconnect on component unmount
  // useEffect(() => {
  //   return () => {
  //     handleDisconnect();
  //   };
  // }, []);

  if (!isConnected)
    return (
      <UserInfoForm
        {...{
          roomName,
          handleRoomName,
          handleConnect,
          isStartMedia,
          username,
          handleUsername,
          user:user,
          // media control
          handleStartMedia,
          localVideo,

          // streaming control
          localStream,
          enableVideoStream,
          handleEnableVideoStream,
          enableAudioStream,
          handleEnableAudioStream,
          ...props
        }}
      />
    );

  return (
    <div
    hidden={props.full_screen == false && props.processo.full_screen == true}
      className={user.accessibility==true && user.type_supervisor!='none' && props.processo?.is_interview==false ? "dark-mode":"Transition-1 white-mode"}
      style={user.accessibility==true && user.type_supervisor!='none' && props.processo?.is_interview==false ?{
        height: "250px",
        width: "250px",
        position: 'fixed',
        bottom: '60px',
        right: 15,
        borderRadius:20,
        // overflow: "hidden",
        display: "grid",
        // gridTemplateColumns: "1fr 30rem",
        gridTemplateColumns:
          show_chat || show_participants || show_info ? "1fr 30rem" : "1fr 0rem",
        background: "var(--background-color1)",
        color: "var(--text-color2)",
        zIndex:1000
      }: {
        height: "100vh",
        width: "100vw",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
        display: "grid",
        // gridTemplateColumns: "1fr 30rem",
        gridTemplateColumns:
          show_chat || show_participants || show_info ? "1fr 30rem" : "1fr 0rem",
        background: "var(--background-color1)",
        color: "var(--text-color2)",
      }}
      id="meet_container"
     
    >
      {user?.accessibility==true && user?.type_supervisor!='none' && props.processo?.is_interview==false && <>
     
      <div className="resizer" onMouseDown={(e)=>{
        positionDragResize='top';
        initDrag(e);
      }}>
      <i className="mdi mdi-resize-bottom-right" style={{fontSize: 20,transform: 'rotate(180deg)',marginLeft: 10,marginTop: 10}}></i><span style={{fontSize:13}}>Ajustar Tamanho</span>
      </div>

      <div className="drag_drop" onMouseDown={(e)=>{
        positionDragResize='bottom';
        initDrag(e);
      }}>
      <i className="mdi mdi-drag" style={{fontSize: 20}}></i>Mover
      </div>
      <div className="pay_attention d-flex justify-content-center">
      <button type="button" 
      onClick={()=>{
        if(connect && payAttention==false){
          console.log({id_msg:uuidv4(), msg:'Candidato chamou atenção do fiscal', aluno_id:props.student.id,processo_id:props.processo.id,sala_id:props.sala_id,faculdade_id:props.faculdade_id})
          socket.emit('pay_attention',{id_msg:uuidv4(), msg:'Candidato chamou atenção do fiscal', aluno_id:props.student.id,processo_id:props.processo.id,sala_id:props.sala_id,faculdade_id:props.faculdade_id})
          setPayAttention(true);
          setTimeout(()=>{
            setPayAttention(false)
          },6000);
        }
      }}
    disabled={!connect || payAttention==true}
      className="btn btn-info btn-sm btn-outline btn-circle m-r-5 " >{payAttention==false? 'Chamar Atenção':'Chamando Atenção...'} <i
            className="mdi mdi-call-made"
            style={{  color: 'white', fontStyle: 'normal',borderRadius:10 }}
            >
                </i></button>
      </div>
     
     
      </>}
      <VideoArea
        {...{
          participants:
            focused_participant === null ? remoteVideos : focused_participant,
          focused_participant,
          setFocusedParticipant,
          playVideo,
          getAdminId: props.getAdminId,
          messageWarning:messageWarning,
          // streaming control
          localStream,
          enableVideoStream,
          handleEnableVideoStream,
          enableAudioStream,
          handleEnableAudioStream,
          // share screen control
          localScreen,
          localStreamScreen,
          isShareScreen,
          handleStartScreenShare,
          handleDisconnectScreenShare,

          handleDisconnect,
          user:user,
          closeCall:props.closeCall,
          // right content control
          show_chat,
          setShowChat,
          show_info,
          setShowInfo,
          show_participants,
          setShowParticipants,
          processo:props.processo
        }}
      />
      <RightContentWrapper
        {...{
          user:user,
          changeVideo,
          participants: remoteVideos,
          focused_participant,
          setFocusedParticipant,
          send_message:props.send_message,
          students:props.students,
          show_chat,
          setShowChat,
          show_info,
          setShowInfo,
          show_participants,
          setShowParticipants,
          playVideo,
          getAdminId: props.getAdminId,
          id_candidato:props.id_candidato,
          get_messages_from_student:props.get_messages_from_student,
        }}
      />
    </div>
  );
}
