import React from 'react';
import Header from './header';

import { DEBUG, URL } from '../variables';
import { connect } from 'react-redux';
import { mudarFaculdade, mudarUser, changeState, mudarStream, mudarDevices, mudarCamModal, logout, mudarProcesso } from '../actions/AppActions';
import ChangeCamModal from './changeCamModal';
import { Redirect } from 'react-router-dom';
import SelectProccess from './SelectProccess';
import { ProgressBar } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';



let silence = () => {
    let ctx = new AudioContext(), oscillator = ctx.createOscillator();
    let dst = oscillator.connect(ctx.createMediaStreamDestination());
    oscillator.start();
    return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
}



//   testSupport([
//     {client: 'Chrome'},
//   ]);

//   function testSupport(supportedDevices) {
//     const deviceDetector = new DeviceDetector();
//     const detectedDevice = deviceDetector.parse(navigator.userAgent);

//     let isSupported = false;
//     for (const device of supportedDevices) {
//       if (device.client !== undefined) {
//         const re = new RegExp(`^${device.client}$`);
//         if (!re.test(detectedDevice.client.name)) {
//           continue;
//         }
//       }
//       if (device.os !== undefined) {
//         const re = new RegExp(`^${device.os}$`);
//         if (!re.test(detectedDevice.os.name)) {
//           continue;
//         }
//       }
//       isSupported = true;
//       break;
//     }
//     if (!isSupported) {
//       alert(`This demo, running on ${detectedDevice.client.name}/${detectedDevice.os.name}, ` +
//             `is not well supported at this time, continue at your own risk.`);
//     }
//   }

// We'll add this to our control panel later, but we'll save it here so we can
// call tick() each time the graph runs.

// Optimization: Turn off animated spinner after its hiding animation is done.


class Termos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_camera: false,
            show_video: false,
            show_screen_sharing: false,
            show_screen: false,
            show_solicitar: true,
            show_aceitar: false,
            redirect: false,
            path: '/processo',
            show_screen: false,
            show_config: false,
            value_progress:0,
        }
        this.props.stream_ref.current = null;
        this.stream_screen = null;

        this.videoRef = React.createRef();
        this.screenRef = React.createRef();

        this.unmont_ = false;

        // this.props.video()=null;
    }
    componentDidMount() {
        console.log(this.props)
        this.props.changeStateParent({ show_face_mesh: false,left_movement:false,face_detected:false,right_movement:false,center_movement:false });
        this.props.stream_ref.current=null;

        // setTimeout(()=>{
        //      if(this.stream_screen!=null){
        //     this.stream_screen.getTracks().map((track)=>track.stop())
        // }
        // if(this.stream!=null){
        //     this.stream.getTracks().map((track)=>track.stop())
        // }
        // console.log('stopp')
        // },10000);
        if (this.props.location.search.includes('error')) {
            this.setState({ msg: this.props.location.search.split('=')[1] });
            this.props.history.replace('/');
        }
        // this.props.mudarStream(null);
        if (this.props.stream_screen != null) {
            try {
                this.props.stream_screen.getVideoTracks()[0].onended = function () { }

                this.props.stream_screen.getTracks().map((track) => track.stop())
            } catch (e) {

            }
        }
        if (this.props.stream != null) {
            try {
                this.props.stream.oninactive = function () { }
                this.props.stream.getTracks().map((track) => track.stop())
            } catch (e) {

            }
        }

        this.props.changeState({ stream_screen: null, stream: null });
        if (this.props.processo.simulado == false) {
            this.logs_timeout = setTimeout(() => {
                this.salvar_log();
            }, this.props.processo.save_timer_pre_exam);
        }

    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
        // this.stream = null;
        this.stream_screen = null;

        if (this.videoRef.current != null) {
            this.videoRef.current.srcObject = null;
        }
        if (this.screenRef.current != null) {
            this.screenRef.current.srcObject = null;
        }
        this.unmont_ = true;

    }

    salvar_log() {
        fetch(`${URL}api/salvar_log?screen=termo&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (resp.processo != null && resp.processo.updated_at != this.props.processo.updated_at) {
                        this.props.mudarProcesso(resp.processo);
                    }
                    if (this.unmont_ == false) {

                        this.logs_timeout = setTimeout(() => {
                            this.salvar_log();
                        }, this.props.processo.save_timer_pre_exam);
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, this.props.processo.save_timer_pre_exam);
                }
            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont_ == false) {

                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, this.props.processo.save_timer_pre_exam);
                }
            });
    }



    salvar_log_permission(msg) {
        fetch(`${URL}api/salvar_log?screen=termo_permission&processo_id=${this.props.processo.id}&processo_name=${this.props.processo.name}&msg=${msg}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);

            });
    }



    solicitar_camera() {
        this.setState({ loading_camera: true, msg: '', show_solicitar: false });
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(() => this.get_camera()).catch(this.handleError.bind(this));
    }

    async get_camera() {

        var devices = await navigator.mediaDevices.enumerateDevices();
        let device_local = localStorage.getItem("deviceId")
        console.log(devices);
        let exists = false;
        let devices_camera = [];
        let devices_mic = [];
        var _stream = null;
        let devices_cam_id = []
        let devices_mic_id = []

        for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind == 'videoinput') {
                if (!devices_cam_id.includes(devices[i].deviceId)) {
                    devices_camera.push(devices[i]);
                    devices_cam_id.push(devices[i].deviceId);

                }
                if (device_local == devices[i].deviceId) {
                    exists = true;
                }
            }
            else if (devices[i].kind == 'audioinput') {
                if (!devices_mic_id.includes(devices[i].deviceId)) {
                    devices_mic.push(devices[i]);
                    devices_mic_id.push(devices[i].deviceId);

                }
            }

        }
        console.log(devices_camera);
        console.log('oi');
        if (devices_camera.length > 0 && devices_mic.length > 0) {
            let use = '';
            if (exists == true) {
                use = device_local;
            }
            else {
                localStorage.setItem("deviceId", devices_camera[0]);
                use = devices_camera[0];
            }
            console.log(use)
            this.props.mudarDevices({ devices_camera: devices_camera, devices_mic, device_select: use })
            navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: use,
                    frameRate: 4,
                    facingMode: "user",
                    width: 320,
                    height: 240
                }, audio: true
            }).
                then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
        }
        else {
            this.setState({ show_solicitar: true, loading_camera: false, msg: 'Nenhuma câmera ou microfone conectado ao seu computador foi reconhecido. Verifique se ambos estão conectados e ativados em seu dispositivo e tente novamente.' })
        }
    }

    handleSuccess(stream) {
        this.setState({show_screen:false})
        this.props.changeStateParent({left_movement:false,face_detected:false,right_movement:false,center_movement:false});    

        // screenshotButton.disabled = false;
        // window.location="/processos_user"
        if (this.props.stream_ref.current) {
            this.props.stream_ref.current.oninactive = () => { }
            this.props.stream_ref.current.getTracks().map((track) => track.stop())
            if (this.stream_screen) {
                this.stream_screen.getVideoTracks()[0].onended = () => {}
                this.stream_screen.getTracks().map((track) => track.stop())
                this.props.changeState({ stream_screen: null })
                this.stream_screen = null;

            }
        }
        this.props.stream_ref.current = stream;
        this.props.stream_ref.current.oninactive = () => {
            this.props.stream_ref.current = null;

            this.props.changeState({ stream: null });
            if (this.stream_screen) {
                this.stream_screen = null;

                this.stream_screen.getVideoTracks()[0].onended = () => {}
                this.stream_screen.getTracks().map((track) => track.stop())
                this.props.changeState({ stream_screen: null })

            }
            this.setState({  show_screen: false, show_aceitar: false, show_solicitar: true, show_screen_sharing: false, show_video: false, loading_camera: false, stream: null, msg: 'Você bloqueou seu acesso a camera. Para liberá-lo vá nas configurações de seu navegador.' });
            this.props.changeStateParent({left_movement:false,right_movement:false,center_movement:false,face_detected:false,show_face_mesh:false})
            this.salvar_log_permission('Cancelou permissão de câmera');

        }
        if (this.props.processo.screen_sharing == true && this.props.processo.type_fiscalization == 'all') {

            this.setState({ show_screen_sharing: true, loading_camera: false, show_video: true }, () => {
                this.props.video().current.style.display = 'flex';
                this.props.video().current.volume = 0;

                this.videoRef.current.style.display = 'flex';
                this.videoRef.current.volume = 0;
                if(!this.props.processo.face_validation){

                    // this.props.video().current.srcObject = stream;
                    this.videoRef.current.srcObject = stream;

                }
                else{
                    console.log('aquiii')
                    this.videoRef.current.srcObject = stream;
                    // this.props.changeStateParent({loading_media:true,show_face_mesh:true});
                }
               
            });
        }
        else {

            this.setState({ show_aceitar: true, loading_camera: false, show_video: true }, () => {
                this.props.video().current.style.display = 'flex';
                this.props.video().current.volume = 0;

                this.videoRef.current.style.display = 'flex';
                this.videoRef.current.volume = 0;
                if(!this.props.processo.face_validation){
                    // this.props.video().current.srcObject = stream;
                    this.videoRef.current.srcObject = stream;

                }
                else{
                    this.videoRef.current.srcObject = stream;
                    // this.props.changeStateParent({loading_media:true,show_face_mesh:true});
                }
            });
        }
        this.salvar_log_permission('Aceitou permissão de câmera');


    }

    aceitar_termos() {
        // this.setState({ loading_camera: true, msg: '' });
        // this.props.mudarStream(this.stream);
        this.props.changeState({ stream_screen: this.stream_screen, stream: this.props.stream_ref.current });

        this.setState({ redirect: true });
    }

    screenSharing() {
        let extended = DEBUG ? false : window?.screen?.isExtended;

        if (extended == true) {
            this.setState({ loading_camera: false, msg: 'Para compartilhar a tela você deve estar com apenas um monitor', show_screen_sharing: true });
            return;
        }
        this.setState({ loading_camera: true, show_screen_sharing: false, msg: '' });
        navigator.mediaDevices
            .getDisplayMedia({
                audio: false, video: {
                    frameRate: 24,
                    displaySurface: "monitor",
                    height: 500
                }
            }
            )
            .then((strm) => {
                let displaySurface = strm.getVideoTracks()[0].getSettings().displaySurface;
                if (displaySurface !== 'monitor') {
                    strm.getTracks().map((track) => track.stop());
                    this.setState({ loading_camera: false, msg: 'Compartilhe a tela cheia', show_screen_sharing: true });
                    this.salvar_log_permission('Compartilhou a opção errada de tela: ' + displaySurface);

                }
                else {
                    let track = silence();
                    strm.addTrack(track);
                    this.stream_screen = strm;
                    this.stream_screen.getVideoTracks()[0].onended = () => {
                        console.log('inactive')
                        this.stream_screen = null;
                        if (this.unmont_ == false) {
                            this.setState({ loading_camera: false, msg: 'Compartilhe a tela cheia para avançar', show_screen_sharing: true, show_screen: false, show_aceitar: false });
                        }
                        else {
                        }
                        this.props.changeState({ stream_screen: null })
                        console.log('teste')
                        this.salvar_log_permission('Cancelou permissão de compartilhamento de tela');

                    }
                    this.screenRef.current.style.display = 'flex';
                    this.screenRef.current.volume = 0;
                    this.screenRef.current.srcObject = strm;
                    this.setState({ show_aceitar: true, loading_camera: false, show_screen: true });
                    this.salvar_log_permission('Aceitou permissão de compartilhamento de tela');
                }
            })
            .catch((err) => {
                this.setState({ loading_camera: false, msg: 'Compartilhe a tela cheia para avançar', show_screen_sharing: true });
                console.error(err)
                try {
                    this.salvar_log_permission('Recusou permissão de compartilhamento de tela: ' + err);
                }
                catch (e) {

                }
            });
    }



    handleError(stream) {
        console.log(stream);
        let msg = '';
        if (stream.toString().includes('Permission denied')) {
            msg = 'Você bloqueou seu acesso a camera. Para liberá-lo vá nas configurações de seu navegador.';
        }
        else if (stream.toString().includes('Permission dismissed')) {
            msg = 'Aceita as permissões de acesso a câmera para poder avançar.';
        }
        else if (stream.toString().includes('Could not start video source')) {
            msg = 'Não foi possível acessar sua câmera. Conecte uma câmera, feche todas suas outras aplicações e tente novamente.';
        }
        else if (stream.toString().includes('Could not start audio source')) {
            msg = 'Não foi possível acessar seu microfone. Conecte um microfone, feche todas suas outras aplicações e tente novamente.';
        }
        else if (stream.toString().includes('NotFoundError')) {
            msg = 'Nenhuma câmera ou microfone conectado ao seu computador foi reconhecido. Verifique se ambos estão conectados e ativados em seu dispositivo e tente novamente.';
        }
        else {
            msg = stream.toString();
        }
        this.setState({ show_solicitar: true, loading_camera: false, stream: null, msg: msg });
        this.salvar_log_permission(msg+' '+stream.toString());
    }

    change_device(event) {
        this.setState({show_screen:false,show_screen_sharing:false,show_aceitar:false});
        this.props.mudarDevices({ devices_camera: this.props.devices_camera, devices_mic: this.props.devices_mic, device_select: event.target.value })
        this.props.changeStateParent({left_movement:false,face_detected:false,right_movement:false,center_movement:false,})
        // console.log(event);
        localStorage.setItem("deviceId", event.target.value);

        navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: event.target.value, frameRate: 4,
                facingMode: "user",
                width: 320,
                height: 240
            }, audio: true
        }).
            then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
    }

    calculateValue(){
        let value = 0;
        if(this.props.processo.type_fiscalization!='none'){

            if(this.props.stream_ref.current!=null){
                // if(this.props.processo.face_validation){
                    value+= (this.props.processo.screen_sharing == true ? 50:100);
                // }
                // else{
                // }
            }
            if(this.stream_screen!=null){
                value+= 50;
            }
        }
        else{
            value=100;
        }

        // if(this.props.face_detected==true){
        //     value+= 20;
        // }
        // if(this.props.left_movement==true && this.props.center_movement && this.props.right_movement){
        //     value+= 20;
        // }

        return value;
    }

    renderTips(){
        if(this.props.processo.face_validation==true){
            if(this.props.loading_media){
                return (<div style={{textAlign:'center',padding:10,textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSIze:18,border:'1px dashed',borderRadius:10,marginTop:10,marginBottom:10,display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress style={{marginRight:5}} size={18} color='inherit'/> <span>Carregando informações...</span></div>)
            }
            else if(this.props.face_detected==false && this.props.multipleface_detected==false){
                return (<p style={{textAlign:'center',textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:10,marginBottom:10,padding:10}}>Nenhum rosto detectado. Mude a posição da câmera ou altere o dispositivo.</p>)
            }
            else if(this.props.face_detected==false && this.props.multipleface_detected==true){
                return (<p style={{textAlign:'center',textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:10,marginBottom:10,padding:10}}>Mais de um rosto detectado. Para entrar na avaliação você deve estar em um ambiente sozinho.</p>)
            }
            else if(this.props.center_movement==false){
                return (<p style={{textAlign:'center',textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:10,marginBottom:10,padding:10}}>Centralize o rosto na câmera para ser detectado</p>)
            }
            else if(this.props.right_movement==false){
                return (<p style={{textAlign:'center',textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:10,marginBottom:10,padding:10}}>Vire seu rosto para o lado direito</p>)
            }
            else if(this.props.left_movement==false){
                return (<p style={{textAlign:'center',textTransform:'uppercase',color:'darkgoldenrod',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:10,marginBottom:10,padding:10}}>Vire seu rosto para o lado esquerdo</p>)
            }
            else {
                return (<p style={{textAlign:'center',textTransform:'uppercase',color:'green',fontWeight:'bold',fontSize:18,border:'1px dashed',borderRadius:10,marginTop:10,marginBottom:10,padding:10}}>Validação de dispositivo concluída</p>)
            }
        }
        else{
            return '';
        }
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <SelectProccess selectProccess={(processo_id) => this.props.selectProccess(processo_id)} processos={this.props.processos} show_proccess={this.props.show_proccess} setShowProccess={(e) => this.props.setShowProccess(e)} />


                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.processo.name}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Nome do candidato: {this.props.user.name}</p>

                            <hr />
                            <div className="row">

                                <div className="col-12 ">
                                    <div className="form-group row">

                                        <div className="col-12 col-md-12" style={{ objectFit: 'contain' }}>


                                            <div style={{ height: '200px' }}>


                                                <div className="project-item" style={{ marginBottom: 0 }}>
                                                    <div className="overlay-container"
                                                        style={{ height: '200px', display: 'flex', justifyContent: 'center' }}>
                                                        {this.props.faculdade.logo != null && <img src={this.props.faculdade.logo} alt="img"
                                                            style={{ height: '200px', objectFit: 'contain', borderRadius: '10px' }} />}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>



                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12 jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.props.faculdade.termos }}>

                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    {this.state.show_video == true && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') &&
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div id="video_show" style={{ position: 'relative',maxWidth:400 }}>
                                                    <b>Câmera:</b>
                                                    
                                                    {/* {this.props.loading_media==true && <div style={{position:'absolute',top:'40%',left:'50%', display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                        <CircularProgress/>
                                                    </div>} */}
                                                    <video  autoPlay muted style={{transform: 'rotateY(180deg)', height: '250px', display: 'none', maxWidth: '100%', borderRadius: 15 }} id="video" ref={this.videoRef}>
                                                    </video>

                                                    <i onClick={() => {
                                                        this.setState({ show_config: !this.state.show_config })
                                                    }} style={{
                                                        fontSize: 30,
                                                        color: 'white',
                                                        position: 'absolute',
                                                        right: 10,
                                                        top: 20,
                                                        cursor: 'pointer'
                                                    }} className='mdi mdi-settings'></i>
                                                    {this.state.show_config == true && <div className="form-group" style={{ marginTop: '1rem' }}>
                                                        <select value={this.props.device_select} className="form-control" id="cameras" onChange={this.change_device.bind(this)}>
                                                            {this.props.devices_camera.map((item, id) => (
                                                                <option key={id} value={item.deviceId}>{item.label}</option>

                                                            ))}
                                                        </select>
                                                        {/* <ChangeCamModal /> */}
                                                    </div>}
                                                </div>
                                                {<div hidden={!this.state.show_screen} id="screen_show " style={{ marginLeft: 5 }}>
                                                    <b>Tela compartilhada:</b>

                                                    <video autoPlay muted style={{ height: '250px', display: 'none', maxWidth: '100%', borderRadius: 15 }} id="screen" ref={this.screenRef}></video>
                                                </div>}

                                            </div>


                                        </div>
                                    }

                                </div>
                                {this.props.processo.type_fiscalization != 'none' && <div className="col-12" style={{ marginTop: 10 }}>
                                    <p style={{textAlign:'center',textTransform:'uppercase'}}><b>Complete todos os requisitos para avançar</b></p>
                                    <b>Requisitos para acesso:</b>
                                    <p style={{ marginBottom: 0, color: (this.props.stream_ref.current != null) ? 'green' : 'red' }}><i className={(this.props.stream_ref.current != null) ? 'fa fa-check' : 'mdi mdi-close'}></i> Compartilhamento de vídeo</p>
                                    <p style={{ marginBottom: 0, color: (this.props.stream_ref.current != null) ? 'green' : 'red' }}><i className={(this.props.stream_ref.current != null) ? 'fa fa-check' : 'mdi mdi-close'}></i> Compartilhamento de áudio</p>
                                    {this.props.processo.screen_sharing == true && <p style={{ marginBottom: 0, color: (this.stream_screen != null) ? 'green' : 'red' }}><i className={(this.stream_screen != null) ? 'fa fa-check' : 'mdi mdi-close'}></i> Compartilhamento de tela cheia</p>}
                                    {/* {this.props.processo.face_validation && <>
                                    <p style={{ marginBottom: 0, color: (this.props.stream_ref.current != null && this.props.face_detected==true) ? 'green' : 'red' }}><i className={(this.props.stream_ref.current != null && this.props.face_detected==true) ? 'fa fa-check' : 'mdi mdi-close'}></i> Rosto detectado</p>
                                    <p style={{ marginBottom: 0, color: (this.props.stream_ref.current != null && this.props.left_movement==true && this.props.right_movement==true && this.props.center_movement==true) ? 'green' : 'red' }}><i className={(this.props.stream_ref.current != null && this.props.left_movement==true && this.props.right_movement==true && this.props.center_movement==true) ? 'fa fa-check' : 'mdi mdi-close'}></i> Movimentações</p>
                                    </>} */}
                                    {/* {this.state.show_video == true && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.renderTips()} */}
                                    <ProgressBar striped variant="success" animated label={"Progresso: "+(this.calculateValue())+'%'} style={{height:50,marginTop:10}} now={this.calculateValue()} max={100}  />
                                </div>}

                                <div className="col-12">
                                    ´<p style={{ textAlign: 'center', color: 'red', fontSize: '1rem' }} id="msg">{this.state.msg}</p>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center" id="button_div">
                                    {this.state.show_solicitar == true && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && <button type="button" onClick={() => this.solicitar_camera()} className="btn btn-success btn-lg waves-effect waves-light"

                                        style={{ width: '100%' }}>Solicitar permissões (Câmera e Microfone)</button>}
                                    {this.state.show_screen_sharing == true && (this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && <button type="button" onClick={() => this.screenSharing()} className="btn btn-success btn-lg waves-effect waves-light"
                                        style={{ width: '100%' }}>Compartilhar tela cheia</button>}


                                    {this.state.loading_camera == true && <div id="loading">
                                        <div className="spinner-border text-primary" role="status"  >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>}


                                    {((this.state.show_aceitar == true 
                                    && this.calculateValue()==100
                                ) || this.props.processo.type_fiscalization == 'none') &&
                                        <button 
                                        disabled={this.calculateValue()<100} 
                                        onClick={this.aceitar_termos.bind(this)} type="button" id="aceitar" className="btn btn-success btn-lg waves-effect waves-light" style={{ width: '100%' }}>Aceitar termos e conceder permissões</button>
                                    }

                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        devices_camera: state.AppReducer.devices_camera,
        devices_mic: state.AppReducer.devices_mic,
        device_select: state.AppReducer.device_select,
        stream: state.AppReducer.stream,
        stream_screen: state.AppReducer.stream_screen,

        videoRef: state.AppReducer.videoRef,

    }
);

export default connect(mapsStateToProps, { changeState, mudarFaculdade, mudarUser, mudarStream, mudarDevices, mudarCamModal, logout, mudarProcesso })(Termos);
