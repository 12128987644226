import * as mpFaceMesh from '@mediapipe/face_mesh';
import * as controls from '@mediapipe/control_utils';
import * as drawingUtils from '@mediapipe/drawing_utils';
import React, { useEffect, useRef } from 'react';
import { Camera } from "@mediapipe/camera_utils"
import { THIS_URL } from '../variables';

const config = {
    locateFile: (file) => {
        // return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@` +
        //     `${mpFaceMesh.VERSION}/${file}`;
        return THIS_URL+'/'+file;
    }
};

const solutionOptions = {
    selfieMode: true,
    enableFaceGeometry: false,
    maxNumFaces: 100,
    refineLandmarks: true,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5
};

const DEBUG=false;


const FaceMesh = (props) => {

    const fpsControl = useRef();
    const interval = useRef();
    const processed = useRef();
    const canvasCtx = useRef();
    const canvasElement = useRef();
    const cameraRef = useRef();
    const faceMeshRef = useRef();
    const unmounRef = useRef();



    const onResults = (results) => {
        // Hide the spinner.
        // document.body.classList.add('loaded');

        // Update the frame rate.
        fpsControl.current.tick();

        // Draw the overlays.
        if(DEBUG){
            canvasCtx.current.save();
            canvasCtx.current.clearRect(0, 0, canvasElement.current.width, canvasElement.current.height);
            canvasCtx.current.drawImage(
                results.image, 0, 0, canvasElement.current.width, canvasElement.current.height);
            console.log(results.multiFaceLandmarks.length)
        }
       
        if (results.multiFaceLandmarks && results.multiFaceLandmarks.length == 1) {
            props.onFaceDetect(true);

            for (let landmarks of results.multiFaceLandmarks) {
                // for (let i = 474; i <= 477; i++) { //pupila esquerda
                //     // landmarks[i].x=0
                //     // landmarks[i].y=0
                //     // landmarks[i].z=0
                // }

                //mc oval
                //i=17 e 18 baixo da face
                //i=0 e 35 cima da face
                //i=7  direita da face
                //i=28  esquerda da face

                //olho direito ic
                //i==0 canto direto
                //i==15 canto esquerdo


                //olho esquerdo kc
                //i==15 canto direto
                //i==0 canto esquerdo

                //lips i 5 embaixo da boca
                //right_eyeBrown i length canto interno sobramcelha
                //right_eyeBrown i 0 canto interno sobramcelha


                let mc = mpFaceMesh.FACEMESH_FACE_OVAL;
                let ic = mpFaceMesh.FACEMESH_RIGHT_EYE;
                let kc = mpFaceMesh.FACEMESH_LEFT_EYE;
                let lips = mpFaceMesh.FACEMESH_LIPS;

                let left_eyeBrown = mpFaceMesh.FACEMESH_LEFT_EYEBROW;






                if (landmarks[mc[7][0]].x - landmarks[ic[0][0]].x < landmarks[kc[0][0]].x - landmarks[mc[28][0]].x
                    &&
                    Math.abs((landmarks[mc[7][0]].x - landmarks[ic[0][0]].x) - (landmarks[kc[0][0]].x - landmarks[mc[28][0]].x)) > 0.02
                ) {
                    // console.log('direita', landmarks[mc[7][0]].x - landmarks[ic[0][0]].x, landmarks[kc[0][0]].x - landmarks[mc[28][0]].x)
                    props.onRightDirection(true);
                }
                else if (landmarks[mc[7][0]].x - landmarks[ic[0][0]].x > landmarks[kc[0][0]].x - landmarks[mc[28][0]].x &&
                    Math.abs((landmarks[mc[7][0]].x - landmarks[ic[0][0]].x) - (landmarks[kc[0][0]].x - landmarks[mc[28][0]].x)) > 0.02
                ) {
                    // console.log('esquerda', landmarks[mc[7][0]].x - landmarks[ic[0][0]].x, landmarks[kc[0][0]].x - landmarks[mc[28][0]].x)
                    props.onLeftDirection(true);
                }
                else {
                    // console.log('centro', landmarks[mc[7][0]].x - landmarks[ic[0][0]].x, landmarks[kc[0][0]].x - landmarks[mc[28][0]].x)
                    props.onCenterDirection(true);
                }

                // landmarks[ic[15][0]].y=0

                // let center = landmarks[kc[15][0]].y +  (landmarks[lips[5][0]].y - landmarks[kc[15][0]].y)/2
                let center = landmarks[mc[0][0]].y + 0.01 + (landmarks[mc[17][0]].y - landmarks[mc[0][0]].y) / 2
                if (center - landmarks[ic[15][0]].y > landmarks[lips[5][0]].y - center
                    // &&
                    // &&
                    // Math.abs((center - landmarks[ic[15][0]].y)-(landmarks[lips[5][0]].y - center))>0.02
                    // Math.abs((landmarks[ic[15][0]].y - landmarks[mc[0][0]].y-0.04)-(landmarks[mc[17][0]].y - landmarks[lips[5][0]].y))>0.015 
                    // &&
                    // Math.abs((landmarks[ic[15][0]].y - landmarks[mc[0][0]].y)-(landmarks[mc[17][0]].y - landmarks[lips[5][0]].y))<=0.05
                ) {
                    props.onUpDirection(true);
                    // console.log('cima', center - landmarks[ic[15][0]].y, landmarks[lips[5][0]].y - center)
                }
                else if ((center - landmarks[ic[15][0]].y) + (center - landmarks[ic[15][0]].y) / 3 < landmarks[lips[5][0]].y - center
                    // &&
                    // Math.abs((center - landmarks[ic[15][0]].y)-(landmarks[lips[5][0]].y - center))>0.02
                    // &&
                    // Math.abs((landmarks[ic[15][0]].y - landmarks[mc[0][0]].y)-(landmarks[mc[17][0]].y - landmarks[lips[5][0]].y))<=0.05
                ) {
                    props.onDownDirection(true);
                    // console.log('baixo', center - landmarks[ic[15][0]].y, landmarks[lips[5][0]].y - center)
                }
                else {
                    props.onMiddleDirection(true);
                    // console.log('meio', center - landmarks[ic[15][0]].y, landmarks[lips[5][0]].y - center)

                }


                // for (let i = 0; i < left_eyeBrown.length; i++) { //pupila esquerda
                //     if (i == left_eyeBrown.length - 1) {
                //         // console.log(i)
                //         landmarks[left_eyeBrown[i][0]].y = center + 0.01
                //         // console.log(kc[15][0].y, lips[5][0].y, kc[15][0].y)

                //         // console.log(landmarks[left_eyeBrown[i][0]].y)
                //     }

                // }

                // for(let i=469;i<=472;i++){ //pupila direita
                //     landmarks[i].x=0
                //     landmarks[i].y=0
                //     landmarks[i].z=0
                // }
                if(DEBUG){
                    drawingUtils.drawConnectors(
                        canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_TESSELATION,
                        { color: '#C0C0C070', lineWidth: 1 });
                    drawingUtils.drawConnectors(
                        canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_RIGHT_EYE,
                        { color: '#FF3030' });
                    drawingUtils.drawConnectors(
                        canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_RIGHT_EYEBROW,
                        { color: '#FF3030' });
                    drawingUtils.drawConnectors(
                        canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_LEFT_EYE,
                        { color: '#30FF30' });
                    drawingUtils.drawConnectors(
                        canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_LEFT_EYEBROW,
                        { color: '#30FF30' });
                    drawingUtils.drawConnectors(
                        canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_FACE_OVAL,
                        { color: '#E0E0E0' });
                    drawingUtils.drawConnectors(
                        canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_LIPS, { color: '#E0E0E0' });
                    if (solutionOptions.refineLandmarks) {
                        drawingUtils.drawConnectors(
                            canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_RIGHT_IRIS,
                            { color: '#FF3030' });
                        drawingUtils.drawConnectors(
                            canvasCtx.current, landmarks, mpFaceMesh.FACEMESH_LEFT_IRIS,
                            { color: '#30FF30' });
                    }
                }
            }
        }
        else if (results.multiFaceLandmarks && results.multiFaceLandmarks.length > 1) {
            // console.log('mais de um rosto')
            props.onMultipleFaceDetect(true);
        }
        else {
            props.onFaceDetect(false);
        }
        processed.current = true;
        if(DEBUG){
            canvasCtx.current.restore();
        }
    }

    const timeoutFaceMesh = async (faceMesh) => {
        // var canvas = document.createElement('canvas');
        // canvas.height = this.videoRef.current.videoHeight;
        // canvas.width = this.videoRef.current.videoWidth;
        // var ctx = canvas.getContext('2d');
        // ctx.drawImage(this.videoRef.current, 0, 0, canvas.width, canvas.height);
        // var img = new Image();
        // img.onload=async(e)=>{
        //     console.log(e)
        // }
        // img.src = canvas.toDataURL();
        try {
            console.log(props.videoRef().current)
            if (props.videoRef().current != null && props.videoRef().current.srcObject != null && processed.current == true) {
                await faceMesh.send({ image: props.videoRef().current });
            }
            setTimeout(() => timeoutFaceMesh(faceMesh), 1000)
        }
        catch (e) {
            setTimeout(() => timeoutFaceMesh(faceMesh), 1000)
        }

    }

    useEffect(() => {
        try{
            unmounRef.current=false;
            props.onLoadMedia(true);

            console.log('iniciou')
        // setTimeout(()=>{
            try{
                if (cameraRef.current) {
                    console.log('stop')
                    cameraRef.current.stop().then((data)=>{
                        console.log('success close',data)
                    }).catch((data)=>{
                        console.log('erro close',data)
                    });;
                }
            } catch(e){
                console.log('error stop');
            }
            try{
                if(faceMeshRef.current){
                    // console.log('closed');
                    faceMeshRef.current.close();
                    faceMeshRef.current.onResult(() => {})
                    faceMeshRef.current=null;
                }
            } catch(e){
                console.log('error close');
            }

        let controlsElement =
            document.getElementsByClassName('control-panel')[0];

        canvasElement.current =
            document.getElementsByClassName('output_canvas')[0];
        canvasCtx.current = canvasElement.current.getContext('2d');
        // canvasCtx.current = canvasElement.current.getContext('2d');
        if (faceMeshRef.current == null) {

            faceMeshRef.current = new mpFaceMesh.FaceMesh(config);
            faceMeshRef.current.setOptions(solutionOptions);
            faceMeshRef.current.onResults(onResults);


            fpsControl.current = new controls.FPS();



            console.log('teste')

            let control = new controls
                .ControlPanel(controlsElement, solutionOptions)
                .add([
                    new controls.StaticText({ title: 'MediaPipe Face Mesh' }),
                    fpsControl.current,
                    // new controls.Toggle({ title: 'Selfie Mode', field: 'selfieMode' }),
                    // new controls.SourcePicker({
                    //     // onVideoElementCreated:()

                    //     onFrame:
                    //         async (input, size) => {
                    //             console.log(input)
                    //             console.log(size)
                    //             input.srcObject=this.videoRef.current.srcObject

                    //             const aspect = size.height / size.width;
                    //             let width, height;
                    //             if (window.innerWidth > window.innerHeight) {
                    //                 height = window.innerHeight;
                    //                 width = height / aspect;
                    //             } else {
                    //                 width = window.innerWidth;
                    //                 height = width * aspect;
                    //             }
                    //             canvasElement.current.width = width;
                    //             canvasElement.current.height = height;
                    //             await faceMesh.send({ image: input });
                    //         },
                    // }),
                    // new controls.Slider({
                    //     title: 'Max Number of Faces',
                    //     field: 'maxNumFaces',
                    //     range: [1, 4],
                    //     step: 1
                    // }),
                    // new controls.Toggle(
                    //     { title: 'Refine Landmarks', field: 'refineLandmarks' }),
                    // new controls.Slider({
                    //     title: 'Min Detection Confidence',
                    //     field: 'minDetectionConfidence',
                    //     range: [0, 1],
                    //     step: 0.01
                    // }),
                    // new controls.Slider({
                    //     title: 'Min Tracking Confidence',
                    //     field: 'minTrackingConfidence',
                    //     range: [0, 1],
                    //     step: 0.01
                    // }),
                ])
                .on(x => {
                    // const options = x;
                    // videoElement.classList.toggle('selfie', options.selfieMode);
                    // faceMesh.setOptions(options);
                });
        }

        processed.current = true
        // interval.current =  setTimeout(()=>timeoutFaceMesh(faceMesh), 1000)
        cameraRef.current = null
        if(props.videoRef().current==null){
            console.log('retornou')
            props.onLoadMedia(false);
            return
        }
        let index=0;
        try{
        faceMeshRef.current.initialize().then(async () => {
            props.videoRef().current.srcObject = props.stream
            cameraRef.current = new Camera(props.videoRef().current, {
                onFrame: async () => {
                    if(unmounRef.current==false){

                        props.onLoadMedia(false);
                        if(index%3==0){
                            props.incrementTotalFrames();
                            try{
                                await faceMeshRef.current.send({ image: props.videoRef().current });
                            }
                            catch(e){
                                console.log(props.videoRef().current)
                                console.log('erro')
                console.log(e)

                            }
                        }
                        index++;
                    }

                },
            });
            await cameraRef.current.start();
            let stream = props.videoRef().current.srcObject;
            props.videoRef().current.srcObject = props.stream

            try {
                stream.getTracks().map((track) => track.stop())
                console.log('oariu')
            } catch (e) {
                console.log('erro')
        console.log(e)


            }
        })
    } catch(e){
        console.log('erro2')
        console.log(e)
        props.onLoadMedia(false);

    }



        // },30000)


        return () => {
            try{
                if (cameraRef.current) {
                    console.log('stop')
                    cameraRef.current.stop().then((data)=>{
                        console.log('success close',data)
                    }).catch((data)=>{
                        console.log('erro close',data)
                    });
                }
            } catch(e){
                console.log('error stop');
            }
            try{
                if(faceMeshRef.current){
                    faceMeshRef.current.close();
                    faceMeshRef.current.onResult(() => {})
                    faceMeshRef.current=null;
                }
            } catch(e){
                console.log('error close');
            }
            unmounRef.current=true;
           

            clearInterval(interval.current)
        }
    } catch(e){
        console.log('erro3')
        console.log(e)
        props.onLoadMedia(false);
    }
    }, []);


    return (
        <div>
            <div className='control-panel' hidden></div>

            <canvas hidden className="output_canvas" height={300} width={300} style={{ height: '300px', width: '100%', maxWidth: '100%', borderRadius: 15 }}>
            </canvas>
        </div>
    );

}

export default FaceMesh;